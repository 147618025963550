export const needsCompleteRegistration = (
    user,
    criteria: { label: string }[] | false = false,
) => {
    const selection = {
        dob: user.age_range === "Unknown",
        gender: !user.gender,
        ethnicity: !user.ethnicity,
        post_code: !user.post_code,
        sexuality:
            criteria &&
            !!criteria.find(
                (criterion) => criterion.label === "sexual orientation",
            ) &&
            !user.sexual_orientation,
    };
    return Object.values(selection).some(Boolean);
};
